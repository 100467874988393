import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Component/landingPage.js";
import ScrollToTop from "./Component/Utility/ScrollToTop.js";
// Home Inner Pages
import Bng from "./Component/Home_Inner_Pages/bng.js";
import Dl from "./Component/Home_Inner_Pages/dl.js";
import Fb from "./Component/Home_Inner_Pages/fb.js";
import Gg from "./Component/Home_Inner_Pages/gg.js";
import Rd from "./Component/Home_Inner_Pages/rd.js";
//Pages of Inner pages  LP1
import BngLp1 from "./Component/Home_Inner_Pages_lp1/BngLp1.js";
import DlLp1 from "./Component/Home_Inner_Pages_lp1/DlLp1.js";
import FbLp1 from "./Component/Home_Inner_Pages_lp1/FbLp1.js";
import GgLp1 from "./Component/Home_Inner_Pages_lp1/GgLp1.js";
import RdLp1 from "./Component/Home_Inner_Pages_lp1/RdLp1.js";
import GgColp1 from "./Component/Home_Inner_Pages_lp1/GgColp1.js";
//Pages of Inner pages  LP2
import BngLp2 from "./Component/Home_Inner_Pages_lp2/BngLp2.js";
import DlLp2 from "./Component/Home_Inner_Pages_lp2/DlLp2.js";
import FbLp2 from "./Component/Home_Inner_Pages_lp2/FbLp2.js";
import GgLp2 from "./Component/Home_Inner_Pages_lp2/GgLp2.js";
import RdLp2 from "./Component/Home_Inner_Pages_lp2/RdLp2.js";
//Pages of Inner pages  LP3
import BngLp3 from "./Component/Home_Inner_Pages_lp3/BngLp3.js";
import DlLp3 from "./Component/Home_Inner_Pages_lp3/DlLp3.js";
import FbLp3 from "./Component/Home_Inner_Pages_lp3/FbLp3.js";
import GgLp3 from "./Component/Home_Inner_Pages_lp3/GgLp3.js";
import RdLp3 from "./Component/Home_Inner_Pages_lp3/RdLp3.js";
//Website Generals Pages

import PrivacyPolicy from "./Component/WebsiteGeneralPages/PrivacyPolicy.js";
import Disclaimer from "./Component/WebsiteGeneralPages/Disclaimer.js";
import TermsCondition from "./Component/WebsiteGeneralPages/TermsCondition.js";
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Home Inner Pages */}
        <Route path="/bng" element={<Bng />} />
        <Route path="/dl" element={<Dl />} />
        <Route path="/fb" element={<Fb />} />
        <Route path="/gg" element={<Gg />} />
        <Route path="/rd" element={<Rd />} />
        {/* Pages of Inner Pages LP1 */}
        <Route path="/bng/lp1" element={<BngLp1 />} />
        <Route path="/dl/lp1" element={<DlLp1 />} />
        <Route path="/fb/lp1" element={<FbLp1 />} />
        <Route path="/gg/lp1" element={<GgLp1 />} />
        <Route path="/rd/lp1" element={<RdLp1 />} />
        <Route path="/gg/colp1" element={<GgColp1 />} />
        {/* Pages of Inner Pages LP2 */}
        <Route path="/bng/lp2" element={<BngLp2 />} />
        <Route path="/dl/lp2" element={<DlLp2 />} />
        <Route path="/fb/lp2" element={<FbLp2 />} />
        <Route path="/gg/lp2" element={<GgLp2 />} />
        <Route path="/rd/lp2" element={<RdLp2 />} />
        {/* Pages of Inner Pages LP3 */}
        <Route path="/bng/lp3" element={<BngLp3 />} />
        <Route path="/dl/lp3" element={<DlLp3 />} />
        <Route path="/fb/lp3" element={<FbLp3 />} />
        <Route path="/gg/lp3" element={<GgLp3 />} />
        <Route path="/rd/lp3" element={<RdLp3 />} />
        {/* Website General Pages */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/terms-and-conditions" element={<TermsCondition />} />
      </Routes>
    </Router>
  );
}

export default App;
