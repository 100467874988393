// src/Utility/useLoadScript.js
import { useEffect } from "react";

const useLoadScript = (scriptSrc) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = scriptSrc;
    script.async = true;
    script.onload = () => console.log("Script loaded successfully");
    script.onerror = (err) => console.error("Script loading error:", err);

    document.head.appendChild(script);

    const bodyScript = script.cloneNode();
    document.body.appendChild(bodyScript);

    const footer = document.querySelector("footer");
    let footerScript;
    if (footer) {
      footerScript = script.cloneNode();
      footer.appendChild(footerScript);
    }

    return () => {
      document.head.removeChild(script);
      document.body.removeChild(bodyScript);
      if (footer && footerScript) {
        footer.removeChild(footerScript);
      }
    };
  }, [scriptSrc]);
};

export default useLoadScript;
